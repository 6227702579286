html {
    width: 100%;
    min-height: 100vh;
}

body {
    /*position: fixed;*/
    top: 0;
    left: 0;
    font-family: 'Lato', sans-serif;
    background-color: #202932;
    background-image: linear-gradient(to top, #0f527c, #110b1f);
    background-attachment: fixed;
    overflow: hidden;
    width: 100%;
    /*min-height: 100vh;*/
    height: 4000px;
    margin: 0;
    padding: 0;
    float: left;
}